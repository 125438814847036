;(function(factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function($) {
    $.faq = {
        init: function () {
            this.filter();
            this.collapse();
            this.searching();
        },
        filter: function () {
            //On ajoute une transition au filtre
            $('.category').css("transition", "all 0.3s ease-in-out");
            //On ajoute un filtre à la 1ère catégorie
            $('.category').first().css("opacity", "0.5");
            $('.category').click(function () {
                //On modifie le titre par la nouvelle catégorie seléctionné
                /* $('.faq-title').text($( this ).attr('category')); */
                $('.faq-title').text('');
                //fermeture des faq 'ouvertes' et affichage des bons icons
                $('.collapse.show').removeClass('show');;
                $('.less.d-block').addClass('d-none').removeClass('d-block');
                $('.more.d-none').removeClass('d-none');
                //On cache toutes les faq pour n'afficher que les bonnes
                $('.searchable').hide();
                //On affiche les nouvelles faq à afficher
                $('div').filter('.' + $( this ).attr('id')).show();
                //On affiche toutes les bordures sauf la derniere
                $('div').filter('.' + $( this ).attr('id')).addClass('faq-border-bottom');
                $('div').filter('.' + $( this ).attr('id')).last().removeClass('faq-border-bottom');
                //On attribue la bonne valeur au select prévu pour le mobile
                $('#category-select').val($( this ).attr('id')).change();
                //On ajoute un filtre à l'image de la catégorie sélectionnée
                $('.category').css("opacity", "1");
                $('.category#' + $( this ).attr('id')).css("opacity", "0.5");
            });
            $('#category-select').on('change', function() {
                //On modifie le titre par la nouvelle catégorie seléctionné
                /* $('.faq-title').text($( this ).find('option:selected').text()); */
                $('.faq-title').text('');
                //fermeture des faq 'ouvertes' et affichage des bons icons
                $('.collapse.show').removeClass('show');
                $('.less.d-block').addClass('d-none').removeClass('d-block');
                $('.more.d-none').removeClass('d-none');
                //On cache toutes les faq pour n'afficher que les bonnes
                $('.searchable').hide();
                //On affiche les nouvelles faq à afficher
                $('div').filter('.' + $( this ).find('option:selected').val()).show();
                //On affiche toutes les bordures sauf la derniere
                $('div').filter('.' + $( this ).attr('id')).addClass('faq-border-bottom');
                $('div').filter('.' + $( this ).attr('id')).last().removeClass('faq-border-bottom');
            });
        },
        collapse: function () {
            function toggleIcon(e) {
                $(e.target)
                    .prev('.panel-heading')
                    .find('.more')
                    .toggleClass('d-none');
                $(e.target)
                    .prev('.panel-heading')
                    .find('.less')
                    .toggleClass('d-none')
                    .toggleClass('d-block')
            }
            $('.faq-group').on('hidden.bs.collapse', toggleIcon);
            $('.faq-group').on('shown.bs.collapse', toggleIcon);
        },
        searching: function(e) {
            $(document).ready(function() {
                (function($) {
                    //En attente que l'utilisateur clique sur le bouton recherche
                    $('.btn-searchbar').click(function() {
                        var filter = $('.search-input').val();
                        if (filter !== "") {
                            //fermeture des faq 'ouvertes'
                            $('.collapse.show').removeClass('show');
                            //On cache toutes les faq pour n'afficher que les bonnes et affichage des bons icons
                            $('.less.d-block').addClass('d-none').removeClass('d-block')
                            $('.more.d-none').removeClass('d-none')
                            $('.searchable').hide();
                            //On remplace le titre de la catégorie par 'Résultat'
                            $('.faq-title').text('Resultats pour: '+ filter);
                            var regex = new RegExp(filter, 'i');
                            var filterResult = $('.searchable').filter(function() {
                                return regex.test($(this).text());
                            })
                            if (filterResult) {
                                filterResult.show()
                            }
                        } else {
                            //fermeture des faq 'ouvertes'
                            $('.collapse.show').removeClass('show');
                            //On cache toutes les faq pour n'afficher que les bonnes et affichage des bons icons
                            $('.less.d-block').addClass('d-none').removeClass('d-block')
                            $('.more.d-none').removeClass('d-none')
                            $('.searchable').hide();
                            //On remplace le titre par la catégory
                            /* $('.faq-title').text($('.category').first().attr('category')); */
                            $('.faq-title').text('');
                            //On affiche les nouvelles faq à afficher
                            $('div').filter('.' + $('.category').first().attr('id')).show();
                            //On affiche toutes les bordures sauf la derniere
                            $('div').filter('.' + $('.category').first().attr('id')).addClass('faq-border-bottom');
                            $('div').filter('.' + $('.category').first().attr('id')).last().removeClass('faq-border-bottom');
                        }
                    });
                }($));
            });
        }
    }
}));