/**
 * CSS
 */
import "./styles/www.scss";
import "aos/dist/aos.css";


import './brain'
/**
 * REQUIRES
 */
const $ = require("jquery");
require("bootstrap");
require("slick-carousel");
require("jquery.cookie-consent");
require("./scripts/www/main");
require('./scripts/www/blog');
require("./scripts/www/faq");
require("./scripts/www/dictionnary");
require("./scripts/www/prenoms");
require("./scripts/www/review");
require("./scripts/www/navbar");

/**
 * Jquery functions
 */
$(document).ready(function () {
    $.main.init();
    $.faq.init();
    $.dictionnary.init();
    $.prenoms.init(); 
    $.review.init();
    $.navbar.init();
});
