;(function(factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function($) {
    $.navbar = {
        init: function () {
            this.listenScroll();
        },
        listenScroll: function () {
            const header = $('header');

            window.addEventListener('scroll', () => {
              if (window.scrollY > 100 ) {
                header.addClass('active');
              } else {
                header.removeClass('active');
              }
            })
        }
    }
}));