const AOS = require("aos");
import { counterUp } from "counterup2";
require("waypoints/lib/noframework.waypoints.js");

(function (factory) {
  "use strict";
  if (typeof define === "function" && define.amd) {
    define(["jquery"], factory);
  } else if (typeof exports !== "undefined") {
    module.exports = factory(require("jquery"));
  } else {
    factory(jQuery);
  }
})(function ($) {
  $.main = {
    init: function () {
      this.scrollLink();
      this.showWebinar();
      this.acceptCookie();
      //this.carousel();
      this.aos();
      this.counterUp();
    },
    showWebinar: function () {
      $(".show-webinars").click(function () {
        $(".webinars-list tr.d-none").removeClass("d-none");
        $(this).addClass("d-none");
      });
    },
    scrollLink: function () {
      $(".scroll-link").click(function (event) {
        $("html, body").animate(
          {
            scrollTop: $($.attr(this, "href")).offset().top,
          },
          500
        );
        event.preventDefault();
      });
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.addEventListener("click", function (e) {
          e.preventDefault();

          const targetId = this.getAttribute("href").substring(1);
          const targetElement = document.getElementById(targetId);

          if (targetElement) {
            targetElement.scrollIntoView({
              behavior: "smooth",
            });
          }
        });
      });
    },
    acceptCookie: function () {
      $(document).ready(function () {
        $("#cookieConsent").cookieConsent();
      });
    },
    carousel: function () {
      $(".carousel").slick({
        arrows: false,
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true,
      });
    },
    aos: function () {
      AOS.init();
    },
    counterUp: function () {
      const el = document.querySelector(".counterup-trigger");
      if (el != null) {
        new Waypoint({
          element: el,
          handler: function () {
            counterUp(el);
            this.destroy();
          },
          offset: "bottom-in-view",
        });
        counterUp(el, {
          duration: 500,
        });
      }
    },
  };
});
