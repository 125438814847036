(function (factory) {
  "use strict";
  if (typeof define === "function" && define.amd) {
    define(["jquery"], factory);
  } else if (typeof exports !== "undefined") {
    module.exports = factory(require("jquery"));
  } else {
    factory(jQuery);
  }
})(function ($) {
  $.dictionnary = {
    init: function () {
      this.dropdown();
      this.dictionnary();
      this.navigation();
    },
    dropdown: function () {
      $(document).ready(function () {
        (function ($) {
          $(".search-input-dictionnary").on("input", function () {
            //On récupère le texte rentré par l'utilisateur
            var search = $(this).val().toLowerCase();

            //On formate la string
            search = $.prenoms.removeAccent(search);
            search = search.replace(/[^a-zA-Z0-9]/g, "");

            //On récupère les lignes
            var liWord = $(".li-word");

            //On affiche les mots correspondants
            liWord.each(function () {
              var word = $(this).attr("data-word");
              var show = word.toLowerCase().indexOf(search) === 0;

              $(this).toggleClass("d-none", !show);
            });
          });

          //Au click sur un mot dans le dropdown
          var liWords = document.querySelectorAll(".li-word");

          liWords.forEach(function(liWord) {
            liWord.addEventListener("click", function() {
              // On récupère le texte du mot
              var word = this.textContent;
          
              // On ajoute le mot dans l'input
              var searchInput = document.querySelector(".search-input-dictionnary");
              if (searchInput) {
                searchInput.value = word;
              }
            });
          });
        })($);
      });
    },
    dictionnary: function () {
      /* plugin PAGINATION */
      $.fn.pageDictionnary = function (opts) {
        var $this = this,
          defaults = {
            perPage: 7,
            showPrevNext: false,
            hidePageNumbers: false,
          },
          settings = $.extend(defaults, opts);

        var listElement = $this;
        var perPage = settings.perPage;
        var children = listElement.children();
        var pager = $(".pagination");

        if (typeof settings.childSelector != "undefined") {
          children = listElement.find(settings.childSelector);
        }

        if (typeof settings.pagerSelector != "undefined") {
          pager = $(settings.pagerSelector);
        }

        var numItems = children.length; // nb de lignes
        var numPages = Math.ceil(numItems / perPage); // nb de pages

        var curr = 0;
        pager.data("curr", curr);

        // chevron left
        if (settings.showPrevNext) {
          $(
            '<li><a href="#" class="prev_link"><span class="material-icons">chevron_left</span></a></li>'
          ).appendTo(pager);
        }

        // affiche les numéros de pagination
        while (numPages > curr && settings.hidePageNumbers == false) {
          $(
            '<li class="num-pagination d-flex"><a href="#" class="page_link">' +
              (curr + 1) +
              "</a></li>"
          ).appendTo(pager);
          curr++;
        }

        // points de suspension
        if (numPages >= 5) {
          $(
            '<li class="dots-end d-flex align-items-center"><p class="text-primary">...</p></li>'
          ).insertBefore(pager.children().eq(numPages));
        }

        // nb de numeros de pagination à afficher
        $(".num-pagination").addClass("d-none");
        $(".num-pagination").slice(pager.data("curr"), 3).removeClass("d-none");
        $(".num-pagination").first().removeClass("d-none"); // affiche le 1er
        $(".num-pagination").last().removeClass("d-none"); // affiche le dernier

        // chevron right
        if (settings.showPrevNext) {
          $(
            '<li><a href="#" class="next_link"><span class="material-icons">chevron_right</span></a></li>'
          ).appendTo(pager);
        }

        // on cache les chevrons
        pager.find(".prev_link").hide();
        $("button.book-prev").hide();
        if (numPages <= 1) {
          pager.find(".next_link").hide();
          $("button.book-next").hide();
        }

        // numéro 1 en gras
        pager.children().eq(1).addClass("active font-weight-bold");

        // affichage des lignes du tableau
        children.hide();
        children.slice(0, perPage).show();

        pager.find("li .page_link").click(function () {
          var clickedPage = $(this).html().valueOf() - 1;
          goTo(clickedPage, perPage);
          return false;
        });

        /* navigation chevrons */
        pager.find(".prev_link").click(function () {
          previous();
          return false;
        });
        pager.find(".next_link").click(function () {
          next();
          return false;
        });

        function previous() {
          var goToPage = parseInt(pager.data("curr")) - 1;
          goTo(goToPage);
        }

        function next() {
          var goToPage = parseInt(pager.data("curr")) + 1;
          goTo(goToPage);
        }
        /* navigation chevrons */

        function goTo(page) {
          var startAt = page * perPage,
            endOn = startAt + perPage;

          children.css("display", "none").slice(startAt, endOn).show();

          // cache chevron left si première page
          if (page >= 1) {
            pager.find(".prev_link").show();
            $("button.book-prev").show();
          } else {
            pager.find(".prev_link").hide();
            $("button.book-prev").hide();
          }

          // cache chevron right si dernière page
          if (page < numPages - 1) {
            pager.find(".next_link").show();
            $("button.book-next").show();
          } else {
            pager.find(".next_link").hide();
            $("button.book-next").hide();
          }

          // récupère le numéro de la page courante
          pager.data("curr", page);

          // cache les numéros
          $(".num-pagination").addClass("d-none");

          // affiche le 1er et le dernier numéro de pagination
          $(".num-pagination").first().removeClass("d-none");
          $(".num-pagination").last().removeClass("d-none");

          // affiche 2 numéros avant et après la page courante
          if (page - 2 < 0) {
            $(".num-pagination")
              .slice(page, page + 3)
              .removeClass("d-none");
          } else {
            $(".num-pagination")
              .slice(page - 2, page + 3)
              .removeClass("d-none");
          }

          // supprime les points de suspension
          $(".dots-start").remove();
          $(".dots-end").remove();

          // ajoute les points de suspension
          if (page + 3 < numPages - 1) {
            $(
              '<li class="dots-end d-flex align-items-center"><p class="text-primary">...</p></li>'
            ).insertBefore(pager.children().eq(numPages));
          }
          if (page >= 4) {
            $(
              '<li class="dots-start d-flex align-items-center"><p class="text-primary">...</p></li>'
            ).insertBefore(pager.children().eq(2));
          }

          // vérifie la présente des points de suspension pour mettre en gras le bon numéro
          pager.children().removeClass("active font-weight-bold");
          if ($(".dots-start").length) {
            pager
              .children()
              .eq(page + 2)
              .addClass("active font-weight-bold");
          } else {
            pager
              .children()
              .eq(page + 1)
              .addClass("active font-weight-bold");
          }
        }

        /* RECHERCHE */
        $(".btn-searchbar-dictionnary").click(function () {
          //On ferme le menu déroulant
          $(".scrollable-menu").removeClass("show");

          // récupère le texte que recherche l'utilisateur et le met en minuscule
          var filter = $(".search-input-dictionnary")
            .val()
            .toString()
            .toLowerCase();

          // enlève les accents
          filter = $.dictionnary.removeAccent(filter);

          // supprime les caractères spéciaux
          filter = filter.replace(/[^a-zA-Z0-9]/g, "");

          //On vérifie si le mot existe
          var wordExist = document.querySelector("." + filter);    

          if (wordExist) {
            // récupère le numéro de la page
            var page = $(".searchable." + filter).attr("data-page");

            // affiche la page du livre qui contient le prénom
            goTo(parseInt(page));

            // affiche le dictionnaire
            $.dictionnary.navigation("dictionnary");
          } else {
            // affiche le message sorry
            $.dictionnary.navigation("sorry");
          }
        });
        /* RECHERCHE */

        // affiche le dictionnaire quand l'utilisateur rentre du texte
        $(".search-input-dictionnary").on("input", function () {
          $.dictionnary.navigation("dictionnary");
        });
      };
      /* end plugin */

      $(document).ready(function () {
        // nombre de lignes du tableau en fonction de la taille de l'écran (responsive)
        var linesPerPage = 0;
        var deviceWidth = $(window).width();

        if (deviceWidth >= 1400) {
          linesPerPage = 14;
        } else if (deviceWidth >= 992 && deviceWidth < 1400) {
          linesPerPage = 10;
        } else if (deviceWidth >= 500 && deviceWidth < 992) {
          linesPerPage = 8;
        } else {
          linesPerPage = 5;
        }

        var nbTotalWords = $("#nbWords").attr("data-length");
        var nbTotalPage = nbTotalWords / linesPerPage;

        // crée un array d'index
        var arrayIndex = [];
        var i = 0;
        while (i < nbTotalPage) {
          for (var j = 0; j < linesPerPage; j++) {
            arrayIndex.push(i);
          }
          i++;
        }
        /* console.log(arrayIndex) : [0, 0, ... * le nb de lignes par page ... , 1, 1, etc..] */

        // envoie le numéro de page au html dans "data-page"
        $(".panel-dictionnary.searchable").each(function () {
          $(this).attr("data-page", arrayIndex[$(this).attr("data-index")]);
        });

        // initialisation pagination
        $("#accordion").pageDictionnary({
          pagerSelector: "#dictionnaryPager",
          childSelector: ".panel-dictionnary",
          showPrevNext: true,
          hidePageNumbers: false,
          perPage: linesPerPage,
        });
      });
    },
    navigation: function (param) {
      if (param == "dictionnary") {
        $(".sorry").addClass("d-none");
        $(".dictionnary").removeClass("d-none");
      } else if (param == "sorry") {
        $(".dictionnary").addClass("d-none");
        $(".sorry").removeClass("d-none");
      }
    },
    removeAccent: function (string) {
      var accent = [
        /[\300-\306]/g,
        /[\340-\346]/g, // A, a
        /[\310-\313]/g,
        /[\350-\353]/g, // E, e
        /[\314-\317]/g,
        /[\354-\357]/g, // I, i
        /[\322-\330]/g,
        /[\362-\370]/g, // O, o
        /[\331-\334]/g,
        /[\371-\374]/g, // U, u
        /[\321]/g,
        /[\361]/g, // N, n
        /[\307]/g,
        /[\347]/g, // C, c
      ];
      var noaccent = [
        "A",
        "a",
        "E",
        "e",
        "I",
        "i",
        "O",
        "o",
        "U",
        "u",
        "N",
        "n",
        "C",
        "c",
      ];

      for (var i = 0; i < accent.length; i++) {
        string = string.replace(accent[i], noaccent[i]);
      }

      return string;
    },
  };
});
