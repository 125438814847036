(function (factory) {
  "use strict";
  if (typeof define === "function" && define.amd) {
    define(["jquery"], factory);
  } else if (typeof exports !== "undefined") {
    module.exports = factory(require("jquery"));
  } else {
    factory(jQuery);
  }
})(function ($) {
  $.review = {
    init: function () {
      this.displayMore();
      this.synchronize();
    },
    displayMore: function () {
      const carousel = $("#carouselTemple");
      const carouselMobile = $("#carouselTempleMobile");
      let maxHeight = getMaxHeight(); // Hauteur maximale initiale.

      //On cache le button de chaque slide
      $("#carouselTemple .carousel-item").each(function () {
        $(".review-more").hide();
      });

      carousel.on("slid.bs.carousel", function (event) {
        if (event.target === this) {
          const reviewContent = $(
            "#carouselTemple .carousel-item.active .review-content"
          );
          const reviewText = $(
            "#carouselTemple .carousel-item.active .review-text"
          );
          const more = $("#carouselTemple .carousel-item.active .review-more");

          if (reviewText.height() > maxHeight) {
            reviewContent.height(maxHeight);
            more.show();
          }
        }
      });

      carouselMobile.on("slid.bs.carousel", function (event) {
        if (event.target === this) {
          const reviewContentMobile = $(
            "#carouselTempleMobile .carousel-item.active .review-content-mobile"
          );
          const reviewTextMobile = $(
            "#carouselTempleMobile .carousel-item.active .review-text-mobile"
          );

          if (reviewTextMobile.height() > maxHeight) {
            reviewContentMobile.height(maxHeight);
          }
        }
      });

      carousel.trigger("slid.bs.carousel");

      // Retourne la taille max du texte à afficher en fonction de la taille de l'écran
      function getMaxHeight() {
        var deviceWidth = $(window).width();

        if (deviceWidth >= 1300) {
          return 82; // 4 lignes
        } else if (deviceWidth >= 992 && deviceWidth < 1300) {
          return 41; // 2 lignes
        } else if (deviceWidth >= 768 && deviceWidth < 992) {
          return 82; // 4 lignes
        } else if (deviceWidth < 768) {
          return 61.5; // 3 lignes
        }
      }
    },
    synchronize: function () {
      $(".carousel-control-prev").on("click", function () {
        slideBothCarousels("prev");
      });

      $(".carousel-control-next").on("click", function () {
        slideBothCarousels("next");
      });

      function slideBothCarousels(direction) {
        var carouselTemple = $("#carouselTemple");
        var carouselTempleMobile = $("#carouselTempleMobile");
        var carouselModal = $("#carouselModal");

        if (direction === "prev") {
          carouselTemple.carousel("prev");
          carouselTempleMobile.carousel("prev");
          carouselModal.carousel("prev");
        } else if (direction === "next") {
          carouselTemple.carousel("next");
          carouselTempleMobile.carousel("next");
          carouselModal.carousel("next");
        }
      }
    },
  };
});
