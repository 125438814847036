;(function(factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function($) {
    $.blog = {
        init: function () {
            this.scrollToLastArticle();
            this.shareArticle();
            this.handleContent();
            this.tagsModal();
        },
        scrollToLastArticle: function () {
            let urlParams = new URLSearchParams(window.location.search);
            let articlesParam = urlParams.get('articles');
            let nbNewArticles = 3
            
            if (articlesParam) {
                let allArticles = document.querySelectorAll('.articles-container .blog-card');
                let firstNewArticle = allArticles[allArticles.length - nbNewArticles];
    
                if (firstNewArticle) {
                    firstNewArticle.scrollIntoView({ behavior: 'smooth' });
                }
            }
        },
        shareArticle: function () {
            const url = window.location.href;
            
            $('.share-mail-btn').on('click', function () {
                shareByEmail()
            })
            $('.share-facebook-btn').on('click', function () {
                shareOnFacebook()
            })
            $('.share-linkedin-btn').on('click', function () {
                shareOnLinkedin()
            })
            $('.share-instagram-btn').on('click', function () {
                shareOnInstagram()
            })
            $('.share-copy-btn').on('click', function () {
                copyUrl()
            })

            function shareOnFacebook() {
                var shareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url);
                openPopup(shareUrl);
            }
            
            function shareOnLinkedin() {
                var shareUrl = 'https://www.linkedin.com/sharing/share-offsite?url=' + encodeURIComponent(url);
                openPopup(shareUrl);
            }
            
            function shareOnInstagram() {
                copyUrl();
                window.open('https://instagram.com', '_blank');
            }
            
            function shareByEmail() {
                var subject = "Regardez cette page intéressante !";
                var body = "Je voulais partager cette page avec vous : " + url;
                var mailtoUrl = 'mailto:?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
                window.location.href = mailtoUrl;
            }

            function copyUrl() {
                var tempInput = document.createElement("input");
                tempInput.style = "position: absolute; left: -1000px; top: -1000px";
                tempInput.value = url;
                document.body.appendChild(tempInput);
                tempInput.select();
                document.execCommand("copy");
                document.body.removeChild(tempInput);
                alert("Lien copié !");
            }

            function openPopup(url) {
                var width = 600;
                var height = 400;
                var left = (screen.width - width) / 2;
                var top = (screen.height - height) / 2;
                window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=' + width + ',height=' + height + ',top=' + top + ',left=' + left);
            }
        },
        handleContent: function () {
            $(document).ready(function() {
                const navigation = $('.article-navigation') 
                var sections = $('.article-full-content h1, .article-full-content h2')

                generateNavigation();

                navigation.on('click', '.link', function(event) {
                    event.preventDefault();
                    var index = $(this).data('index');
                    scrollToSection(index);
                });

                function generateNavigation() {
                    sections.each(function(index) {
                        var link = $('<p>').addClass('link d-flex gap-1 mb-3 pointer');
                        var icon = $('<p>').addClass('sniglet text-lg').text('>');
                        var title = $('<p>').text($(this).text());
                        link.append(icon, title);
                        link.attr('data-index', index);
                        navigation.append(link);
                    });
                }
            
                function scrollToSection(index) {
                    var targetSection = sections.eq(index);
                    $('html, body').animate({
                        scrollTop: targetSection.offset().top - 100
                    }, 'slow');
                }
            })         
        },
        tagsModal: function () {
            const modal = $('.tags-modal');
            const closeBtn = $('.tags-modal .close-btn')
            const openBtn = $('.open-btn')

            openBtn.on('click', function() {
                openModal();
            })

            closeBtn.on('click', function() {
                closeModal();
            })

            function openModal() {
                modal.removeClass('d-none');
            } 

            function closeModal() {
                modal.addClass('d-none')
            }
        }
    }
}));
